import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Breadcrumbs from "../components/common/breadcrumbs/Breadcrumbs";
import Divider from "../components/common/Divider";
import Layout from "../components/common/Layout";
import Meta from "../components/common/Meta";
import { Script } from "gatsby";

const BrochurePage = () => {
  const meta = {
    title: "資料ダウンロード ｜（株）Digital Stacks",
    description:
      "資料ダウンロードは下記フォームにご記入いただき、 次のページで資料を選択してください。Digital Stacksが提供する製品・サービスは、法人のお客様を対象としております。",
    pathname: "/brochure-jp/",
  };
  return (
    <Layout>
      <Meta meta={meta} />
      <Breadcrumbs path="/brochure-jp" className="mt-4 mb-8" />
      <div className="mb-0">
        <div className="px-4 lg:px-[100px]">
          <h1 className="font-sub font-bold text-4xl md:text-[40px] leading-[60px] mb-14 text-dsc-title">
            総合カタログ
          </h1>
          <h2 className="text-[20px] leading-[30px] text-black font-bold mb-4">
            TECHCATALOG 2022年3月号
            <br />
            デジタルトランスフォーミングを加速する
            <br />
            クラウド・モバイルサービス満載の総合カタログ
            <br />
          </h2>
          <p className="text-base leading-5 text-dsc-body tracking-[0.03]">
            資料ダウンロードは下記フォームにご記入いただき、次のページで資料を選択いただく流れになっております。
            <br />
            当社が提供する製品・サービスは、法人のお客様を対象としております。
            <br />
            競合他社および個人の方は、ダウンロードをご遠慮くださいますようお願い申し上げます。
          </p>
        </div>
        <Divider spacing={52} />
        <div className="flex gap-x-6 lg:gap-x-10 flex-col gap-y-10 md:flex-row">
          <div className="md:basis-[35%] pt-5 px-4 md:pr-[0px] lg:pl-[100px] [&_div]:w-full [&_div]:!max-w-none [&_div]:xl:!max-w-[300px]">
            <StaticImage
              src="../../static/images/catalog-download.jpg"
              alt="Catalog Download Image"
              objectFit="contain"
              className="xl:max-h-[424px]"
            />
          </div>
          <div className="md:basis-[60%] lg:pr-[100px]">
            <div className="max-w-[700px] ml-0">
              <div id="contact-form">
                <Script id={`freshworksform-${new Date().getTime()}`} dangerouslySetInnerHTML={{ __html: `
var sc = document.createElement('script');
sc.src = 'https://digitalstackscorporation-team.myfreshworks.com/crm/sales/web_forms/729b50c9d58ea8602a4487eb9f045e27409db531450608c1c6d7dcec15e3c7a3/form.js';
sc.crossOrigin = 'anonymous';
sc.id = 'fs_729b50c9d58ea8602a4487eb9f045e27409db531450608c1c6d7dcec15e3c7a3';
document.getElementById('contact-form').appendChild(sc);

new MutationObserver(function(mutationsList, observer) {
  mutationsList.forEach(mutation => {
    if (mutation.addedNodes) {
      mutation.addedNodes.forEach(node => {
        var css = node.querySelector ? node.querySelector('link[href*="webforms.css"]') : null;
        if (css) {
          observer.disconnect();
          css.addEventListener('load', function() {
            setTimeout(function() {
              loop: for (var i = 0; i < document.styleSheets.length; i++) {
                var styleSheet = document.styleSheets[i];
                if (styleSheet.href && styleSheet.href.includes('webforms.css')) {
                  var rules = styleSheet.rules;
                  for (var j = 0; j < rules.length; j++) {
                    var rule = rules[j];
                    if (rule.selectorText && rule.selectorText.includes('.hidden,')) {
                      var cssText = rule.cssText.replace('.hidden,', '.fs-webform-container .hidden,');
                      styleSheet.deleteRule(j);
                      styleSheet.insertRule(cssText, j);
                      break loop;
                    }
                  }
                }
              }
            });
          });
        }
      });
    }
  });
}).observe(document.body, { childList:true, subtree: true });
          ` }} />
              </div>
              <p className="text-base text-dsc-body px-4"><a target="_blank" href="https://corp.digitalstacks.net/privacy-policy" className="text-dsc-link">個人情報保護方針</a>についてご確認の上、ご同意いただける場合は上記の項目にチェックを入れてご送信ください。</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BrochurePage;
