import React from "react";
import { breadcrumbLinks } from "../../../utils/constants";
import { GoChevronRight } from "react-icons/go";
import Link from "gatsby-link";

type BreadcrumbItemType = {
  isActive: boolean;
  pathname: string;
  fullPath: string;
  customPathname?: string;
};

const BreadcrumbItem: React.FC<BreadcrumbItemType> = ({
  isActive,
  pathname,
  customPathname,
  fullPath,
}) => {
  return (
    <>
      <span>
        <Link to={fullPath}>
          <span
            className={`uppercase text-xs transition-all duration-300 hover:text-dsc-darkshade font-semibold leading-[14px] text-dsc-softgray  ${
              isActive ? "active-breadcrumb" : ""
            }`}
          >
            {customPathname || breadcrumbLinks[pathname]}
          </span>
        </Link>
      </span>
      {!isActive && (
        <span>
          <GoChevronRight color="#C4C4C4" size={12} className="inline mr-2" />
        </span>
      )}
    </>
  );
};

export default BreadcrumbItem;
