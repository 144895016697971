import React from 'react'

type DividerType = {
    spacing: number
}

const Divider: React.FC<DividerType> = ({spacing}) => {
  return (
    <div className='w-full' style={{ minHeight: `${spacing}px` }}></div>
  )
}

export default Divider