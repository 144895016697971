import React, { useCallback } from "react";
import BreadcrumbItem from "./BreadcrumbItem";

type BreadcrumbsType = {
  path: string;
  className?: string;
  customLastTitle?: string;
  customLastPath?: string;
};

const Breadcrumbs: React.FC<BreadcrumbsType> = ({
  path,
  customLastPath,
  customLastTitle,
  className = "",
}) => {
  const pathnames = path.split("/");
  if (pathnames[0] == "") pathnames[0] = "/";

  const generatePathHandler = useCallback(
    (customLastPath?: string, customLastTitle?: string) => {
      let fullPath = "";

      return function handlePath(pathname: string, index: number) {
        const isActive = index == pathnames.length - 1;
        fullPath += pathname == "/" ? pathname : pathname + "/";
        return (
          <BreadcrumbItem
            customPathname={isActive ? customLastTitle : ""}
            pathname={pathname}
            isActive={isActive}
            key={index}
            fullPath={isActive ? customLastPath || fullPath : fullPath}
          />
        );
      };
    },
    [path]
  );

  return (
    <div
      className={`px-4 xl:px-[70px] [&_.active-breadcrumb]:text-dsc-title ${className}`}
    >
      {pathnames.length > 0 &&
        pathnames.map(generatePathHandler(customLastPath, customLastTitle))}
    </div>
  );
};

export default Breadcrumbs;
